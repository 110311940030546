@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,300);
@import "../../node_modules/bootstrap/less/bootstrap.less";

// Bootstrap overrides.
@font-family-sans-serif: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-size-base: 16px;
@text-color: #454545;
@error-color: #EC0B04;
@headings-font-weight: 600;
@input-border: #C1CEDE;
@border-radius-base: 3px;
@input-group-addon-bg: #E8F1FC;
@input-group-addon-border-color: #4B92E3;

html, body {
	width: 100%;
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
}

#container {
	height: 100%;
}

.light {
	font-weight: 300;
}

.storm-view-content {
	.container-fluid;

	position: relative;
	top: 51px;
	height: ~"calc(100% - 51px)";
	overflow: auto;
	background-color: #FFF;
}

.modal-backdrop {
	z-index: 9999;
}

.modal {
	z-index: 10000;
}

.form-actions {
	.button {
		display: inline;
	}
}

.dl-horizontal {
	dd {
		min-height: 20px;
	}
}

.title-case {
	text-transform: capitalize;
}

.stormql-editor {
	.modal-body {
		overflow-y: auto;
	}
}

.stormql-editor textarea {
	height: 150px;
}

.add-form {
	background-color: #FCFCFC;
	border-bottom: 1px solid #DBDBDB;
	overflow: hidden;
	max-height: 500px;
	transition: max-height 0.25s linear;

	&.zero-height {
		max-height: 0 !important;
		border-bottom: 0;
	}
}

.actions-col {
	padding-top: 12px;
	text-align: right;
}

.header-with-buttons {
	* {
		display: inline-block;
		margin-left: 15px;
		&:first-child {
			margin-left: 0px;
		}
	}
	@media (max-width: @screen-xs-max) {
		* {
			display: block;
			margin-left: 0px;
			margin-bottom: 5px;
		}
	}
	margin: 10px 0 15px 0;
	p {
		text-align: center;
		font-weight: bold;
		font-size: 20px;
	}

	button, a {
		float: right;
		position: relative;
		@media (max-width: @screen-xs-max) {
			float: none;
			position: inherit;
		}
	}

}

// Override for input groups.
.input-group-addon {
	&:first-child {
		border-right: 1px solid @input-group-addon-border-color;
	}

	&:last-child {
		border-left: 1px solid @input-group-addon-border-color;
	}
}

.error-message {
	color: @error-color;
}

.input-group {
	.form-control:last-child {
		border-left: 0;
	}

	.form-control.has-error:last-child {
		border-color: @error-color;
	}

	.form-control:first-child {
		border-right: 0;
	}

	.select-radius {
		border-radius: 5px 0px 0px 5px;
		-webkit-appearance: none;
	}
}

.form-group {
	input.has-error, select.has-error {
		border-color: @error-color;
	}
}

.form-control {
	&-margin {
		margin-top: 5px;
	}
}

.list-unstyled {
	padding-right: 0;
}

.red-delete {
	color: hsl(0, 0%, 72%);
	cursor: pointer;
	&:hover {
		color: rgb(208, 30, 30);
	}
}

.blue-settings {
	color: hsl(0, 0%, 72%);
	cursor: pointer;
	&:hover {
		color: @input-group-addon-border-color;
	}
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	 -webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Firefox */
			 -ms-user-select: none; /* Internet Explorer/Edge */
					 user-select: none; /* Non-prefixed version, currently
																 supported by Chrome and Opera */
}

.api-root {
	position: absolute;
	right: 0;
	margin: 5px;
	font-size: 10px;
}

.styled-select {
	transition: opacity 0.35s ease-out;

	&.is-disabled {
		opacity: 0.5;
	}
}
